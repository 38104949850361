/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Helvetica, Arial, "PingFang SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;

    // padding-top: env(safe-area-inset-top);
    // padding-right: env(safe-area-inset-right);
    // padding-bottom: env(safe-area-inset-bottom);
    // padding-left: env(safe-area-inset-left);
}

// Dialog Radius Border
.custom-container .mat-mdc-dialog-surface {
    border-radius: 20px !important;
}

// Dialog Close Btn
.cdk-overlay-pane.my-dialog {
    position: relative !important;
}

mat-checkbox ::ng-deep .mat-checkbox-inner-container {
    width: 60px;
    height: 60px;
}

/* Set top-lef and bottom-left corner radius */
:host ::ng-deep .mat-form-field-outline-start {
    border-radius: 15px 0 0 15px !important;
    min-width: 15px !important;
}

/* Set top-right and bottom-right corner radius */
:host ::ng-deep .mat-form-field-outline-end {
    border-radius: 0 15px 15px 0 !important;
}
